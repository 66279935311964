import React from "react";
import LogoCards from "../../assets/images/logocards.webp";
import CardsFly from "../../assets/images/cards.webp";
import People from "../../assets/images/people.webp";
import Screens from "../../assets/images/screens.webp";
import Time from "../../assets/images/time.webp";
import Tree from "../../assets/images/tree.webp";
import BashBienvenida from "../../assets/images/bash-bienvenida.webp";
import Seed from "../../assets/images/seed.webp";
import Footer from "../../assets/images/footer.webp";
import Btn from "../../components/Btn";
import Carrousel from "../../components/Carrousel";
const HomePage = () => {
  return (
    <>
      <div className="w-100 h-100vh h-pr-fl-ma noselect t-a-c of-x-n of-y-a f-w-bo p-5px b-s-b-b ">
        <div className="w-100 h-a h-pr-fl-ma b-s-b-b f-w-bo ">
          <div className="w-100 h-100  h-pr-fl-ma b-s-b-b of-h ">
            <img
              src={LogoCards}
              className="w-100   mobile-w-1000px mobile-h-100 obj-f-cov  h-a h-pr-fl-ma r-h-c"
            />
 <div className="w-100 h-100 pa m-a t-0px b-0px l-0px r-0px h-pr-fl-ma b-s-b-b of-h ">
<div className="w-100 h-a pa m-a  b-100px l-0px r-0px h-pr-fl-ma b-s-b-b  h-a noselect fx-t-s-1 t-a-c  h-pr-fl-ma ns-f-s-big mobile-f-s-40px c-white ns-f-poppins-extrabold">
             Hace un <span className="fx-t-n-blue ns-f-poppins-extrabold">tiempo</span>
                </div>
                </div>
          </div>
          
        </div>
        <div className="w-100 h-50px h-pr-fl-ma b-s-b-b f-w-bo  ">&nbsp;</div>

        <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo  ">
          <div className="w-80 r-h-c h-100 h-pr-fl-ma b-s-b-b ">
            <img
              src={People}
              className="w-100 h-100 r-h-c mobile-w-100 mobile-h-100 obj-f-cov h-pr-fl-ma of-v "
            />
             <div className="w-100 h-100 pa m-a t-0px b-0px l-0px r-0px h-pr-fl-ma b-s-b-b of-h ">
<div className="w-100 h-a pa m-a mobile-l-h-50px b-100px l-0px r-0px h-pr-fl-ma b-s-b-b  h-a noselect fx-t-s-1 t-a-c  h-pr-fl-ma ns-f-s-big mobile-f-s-50px c-white ns-f-poppins-extrabold">
             Un <span className="fx-t-n-fuchsia ns-f-poppins-extrabold">equipo</span> de <span className="fx-t-n-fuchsia ns-f-poppins-extrabold">personas</span>
                </div>
                </div>
          </div>
        </div>

     

        <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo  ">
          <div className="w-100 r-h-c h-100 h-pr-fl-ma b-s-b-b ">
                <span className="w-100 h-a noselect mobile-l-h-50px pa m-a l-0px r-0px b-0px t-50px   t-a-c  ns-f-s-giant mobile-f-s-40px c-white ns-f-poppins-extrabold">
                 Sembramos una<br />
                 Semilla de<br />
                 <span className="fx-t-n-green ns-f-poppins-extrabold">Oportunidades</span>
                </span>
          
            <img
              src={Seed}
              className="w-100 h-a r-h-c mobile-w-100 mobile-h-100 obj-f-cov h-pr-fl-ma of-v "
            />
          </div>
        </div>



        <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo  ">
          <div className="w-100  h-100 h-pr-fl-ma b-s-b-b ">
            <img
              src={CardsFly}
              className="w-100  h-100 r-h-c mobile-w-100 mobile-h-100 obj-f-cov h-pr-fl-ma of-v "
            />
             <div className="w-100 h-a  noselect  pa m-a l-0px r-0px b-0px t-a-c ns-f-s-giant  c-white ns-f-poppins-extrabold">
                <span className="w-100 h-pr-fl-ma ns-f-poppins-extrabold ns-f-s-big mobile-l-h-50px mobile-f-s-30px">Construimos una</span>
                <span className="w-100 h-pr-fl-ma  ns-f-s-giant mobile-f-s-50px fx-t-n-blue ns-f-poppins-extrabold mobile-l-h-50px z-i-2">RED SOCIAL</span>
               <span className="w-100 h-pr-fl-ma  fx-t-n-fuchsia ns-f-poppins-extrabold ns-f-s-big mobile-f-s-40px mobile-l-h-50px">LatinoAmericana</span>
  
                </div>
          </div>
        </div>
        <div className="w-100 h-200px h-pr-fl-ma b-s-b-b f-w-bo  ">&nbsp;</div>
   
        <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo  ">
          <div className="w-100 h-100 h-pr-fl-ma b-s-b-b ">
            <img
              src={Time}
              className="w-100 h-100 r-h-c mobile-w-100 mobile-h-100 obj-f-cov h-pr-fl-ma of-v "
            />
              <div className="w-100 h-a fx-t-s-1 noselect mobile-l-h-50px pa m-a l-0px r-0px b-0px t-0px t-a-c ns-f-s-big mobile-f-s-40px c-white ns-f-poppins-extrabold">
              <div className="w-100 h-a h-pr-fl-ma r-v-c ns-f-poppins-extrabold">
               <span className="w-100 h-pr-fl-ma fx-t-n-green ns-f-poppins-extrabold"> para darle valor al tiempo</span> <br />
             <span className="w-100 h-pr-fl-ma fx-t-n-orange ns-f-poppins-extrabold">que pasamos en Internet</span>
                </div>
                </div>
          </div>
        </div>

       

        <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo ">
          <img
            src={Screens}
            className="w-100 h-100  pa m-a l-0px r-0px b-0px t-0px  mobile-w-100 mobile-h-100 obj-f-cov  of-v "
          />

          <div className="w-100 h-80 h-pr-fl-ma  b-s-b-b of-h">
            <div className="w-70 mobile-w-90 h-100 centered h-pr-fl-ma  b-s-b-b ">
              <div className="h-pr-fl-ma mobile-h-90 a-r-16-9 b-s-b-b centered of-h">
                {" "}
                <iframe
                  className="mobile-h-100 mobile-w-100 pa m-a l-0px r-0px t-0px b-0px "
                  width="100%"
                  height="90%"
                  src="https://www.youtube.com/embed/YXDKPWqq8I4?si=rnSTtQO77oDcOT0h"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="w-100 h-20 h-pr-fl-ma b-s-b-b ">
            <div className="noselect w-100 h-a h-pr-fl-ma b-s-b-b f-w-bo r-v-c ">
              <span className="w-100 mobile-l-h-20px h-a t-a-c h-pr-fl-ma c-white ns-f-s-medium mobile-f-s-15px ns-f-poppins-bold">
                Enterate de todos nuestros avances en:
              </span>
              <span className="w-100 mobile-l-h-40px h-a t-a-c ns-f-s-big  mobile-f-s-35px c-white ns-f-poppins-extrabold">
                @ElCanalDeNick
              </span>
              <div className="noselect w-100 p-5px mobile-l-h-10px h-a t-a-c h-pr-fl-maf-w-bo r-h-c c-white ns-f-s-normal">
                Estamos en{" "}
                <span className="ns-bg-red p-t-2px p-b-2px p-l-5px p-r-5px ns-br-3">
                  <a href="https://www.youtube.com/@elcanaldenick">Youtube</a>
                </span>{" "}
                & también en{" "}
                <span className="bg-black p-t-2px p-b-2px p-l-5px p-r-5px ns-br-3">
                  <a href="https://www.tiktok.com/@elcanaldenick">Tiktok</a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 h-200px mobile-h-20px h-pr-fl-ma b-s-b-b f-w-bo  ">
          &nbsp;
        </div>
        <div className="w-100 h-a h-pr-fl-ma ">
       
          <div  className="w-1000px mobile-w-95 h-a r-h-c h-pr-fl-ma">
          <Carrousel></Carrousel>
       
          </div>
          
          </div>
          <div className="w-100 h-100px mobile-h-20px h-pr-fl-ma b-s-b-b f-w-bo  ">
          &nbsp;
        </div>
   
   <div className="w-100 h-100 h-pr-fl-ma b-s-b-b f-w-bo  ">
     <div className="w-100 h-100 h-pr-fl-ma b-s-b-b ">
       <img
         src={Tree}
         className="w-100 h-100 r-h-c mobile-w-100 mobile-h-100 obj-f-cov h-pr-fl-ma of-v "
       />
         <div className="w-100 h-a fx-t-s-1 noselect mobile-l-h-50px pa m-a l-0px r-0px b-0px t-0px t-a-c ns-f-s-big mobile-f-s-40px c-white ns-f-poppins-extrabold">
         <div className="w-100 h-a h-pr-fl-ma r-v-c ns-f-poppins-extrabold">
          <span className="w-100 h-pr-fl-ma ns-f-poppins-extrabold">tú ayuda es</span> <br />
        <span className="w-100 h-pr-fl-ma fx-t-n-green ns-f-poppins-extrabold">muy importante</span>
           </div>
           </div>
     </div>
   </div>





        <div className="w-100 h-a h-pr-fl-ma b-s-b-b f-w-bo ">
        <div className="w-100 h-a pa m-a l-0px r-0px b-0px b-s-b-b">
          <img
            src={Footer}
            className="noselect w-100 mobile-w-1000px h-a h-pr-fl-ma r-h-c  mobile-w-100  obj-f-cov  of-v "
          />{" "}
        </div>
          <div className="w-100 h-a h-pr-fl-ma m-b-20px   b-s-b-b f-w-bo">
            <span className="noselect w-100 mobile-l-h-20px h-a t-a-c h-pr-fl-ma h-a  c-white ns-f-s-medium mobile-f-s-15px ns-f-poppins-bold">
              Conviertete en nuestro sponsor y
            </span>
            <span className="noselect w-100 mobile-l-h-40px h-a t-a-c ns-f-s-big mobile-f-s-35px c-white ns-f-poppins-extrabold">
              #Hazlo<span className="fx-t-n-blue ns-f-poppins-extrabold">Posible</span>
            </span>
          </div>

          <div className="noselect w-100 h-a h-pr-fl-ma m-b-20px b-s-b-b f-w-bo p-10px ">
            <Btn
              text="Hacer donativo"
              enlace="https://www.paypal.com/paypalme/nodesystemsuy"
              className="p-20px ns-bg-b-1 r-h-c c-white ns-br-3"
            ></Btn>
          </div>
          <div className="noselect w-100 p-5px l-h-15px h-a t-a-c h-pr-fl-ma h-a f-w-bo r-h-c c-white ns-f-s-normal">
            Realiza un donativo de forma fácil y segura <br />
            con la tecnología de <span className="ns-c-success">Paypal</span>.
          </div>
          <div className="w-500px h-a mobile-w-80  h-pr-fl-ma  r-h-c ">
            <div className="noselect mobile-d-n fx-t-s-1 w-100 p-5px mobile-l-h-30px h-a  h-pr-fl-ma  f-w-bo r-h-c c-white ns-f-s-medium">
              Todos los donativos son{" "}
              <span className="ns-c-success">100% anónimos</span>
            </div>
            <div className="noselect d-n mobile-d-b fx-t-s-1  w-100 p-5px  h-a  h-pr-fl-ma  f-w-bo r-h-c c-white ns-f-s-medium">
              Todos los donativos son <br />
              <span className="ns-c-success">100% anónimos</span>
            </div>
            <div className="noselect w-100 r-h-c p-5px l-h-15px fx-t-s-1  t-a-c h-pr-fl-ma h-a f-w-bo r-h-c c-white ns-f-s-normal">
              Nos encantaría saber de ti y que seas parte de los créditos de
              nuestra red social una vez esté finalizada, solo debes comunicarte
              con nosotros en{" "}
              <span className="ns-c-brand-1">
                <a
                  className="c-p h-e"
                  href="mailto:nodesystems@gmail.com?subject=¡Quiero%20Ser%20Sponsor!"
                >
                  nodesystems@gmail.com
                </a>
              </span>{" "}
              con el asunto <br />"
              <span className="ns-c-success">¡Quiero Ser Sponsor!</span>"
            </div>
          </div>
          <div className="w-100 h-100px h-pr-fl-ma b-s-b-b   ">&nbsp;</div>
        

        <div className="w-100 h-a h-pr-fl-ma  b-s-b-b ">
          <img
            src={BashBienvenida}
            className="noselect w-50 h-a h-pr-fl-ma r-h-c  mobile-w-100  obj-f-cov  of-v "
          />{" "}
        </div>


        <div className="noselect w-100 p-5px ns-f-s-big mobile-f-s-35px l-h-50px h-a t-a-c h-pr-fl-ma h-a f-w-bo r-h-c c-white ns-f-poppins-extrabold">
          ¡MUCHAS GRACIAS!
        </div>

        <div className="w-100 h-100px h-pr-fl-ma b-s-b-b f-w-bo  ">&nbsp;</div>

        
        <div className="noselect w-100 p-5px  ns-f-s-small  h-a t-a-c h-pr-fl-ma h-a f-w-bo r-h-c c-white ns-f-poppins-bold">
      
          © NodeSystemsUy&reg; | 2012 - 2023 | Todos los derechos reservados.
        </div>
        <div className="w-100 h-100px h-pr-fl-ma b-s-b-b f-w-bo  ">&nbsp;</div>

       
        </div>


      </div>
    </>
  );
};

export default HomePage;
