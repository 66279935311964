import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './assets/styles/seed-common.css'


import './App.css';

import { HomePage } from './pages/'
/*import {RegisterForm, LoginForm } from './components/'; */


function App() {
  return (
    <BrowserRouter>
          <div className='w-100vw h-100vh h-pr-fl-ma b-s-b-b bg-special-1'>
          <div className='w-100vw h-100 h-pr-fl-ma b-s-b-b background-gd-1' >
    <Routes> 
      <Route path='/' element={<HomePage />} />
    </Routes>
    </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
