import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const Carrousel = () => {
  return (
    <>
      <Swiper
        className="w-100 h-pr-fl-ma h-a"
        spaceBetween={50}
        slidesPerView={1}
        modules={[Navigation, Pagination]}
        navigation
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        keyboard
        loop={true}
      
      >
         {/* SLIDE 1 ------------------------------------------------------------------------ SLIDE 1 */}
        <SwiperSlide>
          {" "}
          <div className="w-100  ns-br-9 ns-bg-3 h-a r-h-c h-pr-fl-ma b-s-b-b f-w-bo flexbox mobile-f-d-c p-10px">
            <div
              width={"380px"}
              height={"500px"}
              className="ns-br-9 of-h mobile-w-100 h-a h-pr-fl-ma b-s-b-b  "
            >
              <div className="w-100 h-100 h-pr-fl-ma  ns-br-2 ns-br-9 of-h">
                <img
                  className="w-100 h-100 h-pr-fl-ma"
                  src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExNWVpdnZ0M3Z3cWVtMmtqMHd4bHFrZDBvM2h1bjdpeDVqemM1M3ltMSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/0c5zDx8b939EvfnfSA/giphy.gif"
                  alt=""
                />
              </div>
            </div>
            <div className="w-70 mobile-w-100 h-pr-fl-ma b-s-b-b t-a-c p-20px ">
              <div className="noselect w-100 h-a  ns-f-s-big c-white ns-f-poppins-extrabold">
                {/* DESKTOP */}
                <span className="d-b mobile-d-n ns-f-poppins-extrabold ">
                  Seamos la
                  <br />
                  oportunidad de
                  <br />
                  alguien más.
                </span>
                {/* MOBILE */}
                <p className="mobile-d-b d-n ns-f-poppins-extrabold mobile-f-s-40px  l-h-40px ">
                  Seamos la oportunidad de alguien más.
                </p>

                {/* DESKTOP */}
                <span className="mobile-d-n noselect w-100 h-a m-t-20px h-pr-fl-ma l-h-30px c-white ns-f-s-medium mobile-f-s-25px  ">
                  Como desarrolladores,
                  <br />
                  productores de contenido y/o
                  <br /> emprendedores digitales,
                  <br />
                  podemos hacer muchas cosas,
                  <br />
                  <p className="ns-c-brand-1 ns-f-poppins-bold">
                    {" "}
                    pero JUNT@S como personas,
                    <br />
                    podemos hacer muuucho más...
                  </p>
                </span>
              </div>
              {/* MOBILE */}
              <span className="d-n mobile-d-b noselect w-100 h-a m-t-20px h-pr-fl-ma l-h-25px c-white mobile-f-s-20px  ">
                Como desarrolladores,
                <br />
                productores de contenido y/o
                <br /> emprendedores digitales,
                <br />
                podemos hacer muchas cosas,
                <br />
                <p className="ns-c-brand-1 ns-f-poppins-bold">
                  {" "}
                  pero JUNT@S como personas,
                  <br />
                  podemos hacer muuucho más...
                </p>
              </span>
            </div>
          </div>
        </SwiperSlide>

        {/* SLIDE 2 ------------------------------------------------------------------------ SLIDE 2 */}

        <SwiperSlide>
          {" "}
          <div className="w-100  ns-br-9 ns-bg-3 h-a r-h-c h-pr-fl-ma b-s-b-b f-w-bo flexbox mobile-f-d-c p-10px">
            <div
              width={"380px"}
              height={"500px"}
              className="ns-br-9 of-h mobile-w-100 h-a h-pr-fl-ma b-s-b-b  "
            >
              <div className="w-100 h-100 h-pr-fl-ma  ns-br-2 ns-br-9 of-h">
                <img
                  className="w-100 h-100 h-pr-fl-ma"
                  src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExcWQ5aGtybzJheWg1M21pa3Vkb2JjamU1YmdmOTIyOHJ6MGY2bXB5ciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/1FkUnOJwIp6E4hYLk9/giphy.gif"
                  alt=""
                />
              </div>
            </div>
            <div className="w-70 mobile-w-100 h-pr-fl-ma b-s-b-b t-a-c p-20px ">
              <div className="noselect w-100 h-a  ns-f-s-big c-white ns-f-poppins-extrabold">
                {/* DESKTOP */}
                <span className="d-b mobile-d-n ns-f-poppins-extrabold ">
                  Conectemos
                  <br />
                  con el mundo
                  <br />
                  real.
                </span>
                {/* MOBILE */}
                <p className="mobile-d-b d-n ns-f-poppins-extrabold mobile-f-s-40px  l-h-40px ">
                 Conectemos con el mundo real.
                </p>

                {/* DESKTOP + MOBILE */}
                <span className=" noselect w-100 h-a m-t-20px h-pr-fl-ma l-h-30px mobile-l-h-25px c-white ns-f-s-medium mobile-f-s-20px ">
                  Hagamos del contacto humano,
                  <br />
                 una forma mutua de crecer.
                  <br /> Más unidos, más libres,
                  <br />
                  para hacer de este mundo
                  <br />
                  <p className="ns-c-success f-w-bo ns-f-poppins-bold">
                    {" "}
                    el planeta de las posibilidades,
                    <br />
                    sin importar la distancia...
                  </p>
                </span>
              </div>
             
            </div>
          </div>
        </SwiperSlide>

{/* SLIDE 3 ------------------------------------------------------------------------ SLIDE 3 */}

<SwiperSlide>
          {" "}
          <div className="w-100  ns-br-9 ns-bg-3 h-a r-h-c h-pr-fl-ma b-s-b-b f-w-bo flexbox mobile-f-d-c p-10px">
            <div
              width={"380px"}
              height={"500px"}
              className="ns-br-9 of-h mobile-w-100 h-a h-pr-fl-ma b-s-b-b  "
            >
              <div className="w-100 h-100 h-pr-fl-ma  ns-br-2 ns-br-9 of-h">
                <img
                  className="w-100 h-100 h-pr-fl-ma"
                  src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExczg2anEydHlqdW53b3RwZjN5ZDgybW12dzJtY3FhZ3EzcHFtaHQxYSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/1d87qwDrauSIunYuFV/giphy.gif"
                  alt=""
                />
              </div>
            </div>
            <div className="w-70 mobile-w-100 h-pr-fl-ma b-s-b-b t-a-c p-20px ">
              <div className="noselect w-100 h-a  ns-f-s-big c-white ns-f-poppins-extrabold">
                {/* DESKTOP */}
                <span className="d-b mobile-d-n ns-f-poppins-extrabold ">
                  Descubramos
                  <br />
                  nuevos caminos
                  <br />
                  hacia el futuro.
                </span>
                {/* MOBILE */}
                <p className="mobile-d-b d-n ns-f-poppins-extrabold mobile-f-s-40px  l-h-40px ">
                 Descubramos nuevos caminos hacia el futuro.
                </p>

                {/* DESKTOP + MOBILE */}
                <span className=" noselect w-100 h-a m-t-20px h-pr-fl-ma l-h-30px mobile-l-h-25px c-white ns-f-s-medium mobile-f-s-20px ">
                  Tenemos la tecnología
                  <br />
                para darle valor al tiempo
                  <br /> que utilizamos en la red.
                  <br />
                 Es nuestro deber compartirla...
                  <br />
                  <p className="ns-c-success f-w-bo ns-f-poppins-bold">
                    {" "}
                   Es nuestra misión, hacer posible
                    <br />
                    que tengas más oportunidades.
                  </p>
                </span>
              </div>
             
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
    </>
  );
};

export default Carrousel;
