import React from 'react';
import PropTypes from 'prop-types';

const Btn = ({ type, enlace, children,icon, iconLeft, altIconLeft, iconRight, altIconRight, text, size, className, onClick }) => {
  return (
    <>
      {enlace ? (
        <a href={`${enlace}`}>
       <button
          className={`btn button ${type} ${className} h-pr-fl-ma ${size} flexbox f-w-n-w b-s-b-b`}
          onClick={onClick}
        >
          {iconLeft && <div className='w-and-h-25px h-pr-fl-ma b-s-b-b m-r-5px'><div className='w-a h-a centered'>{iconLeft}</div></div>}
          {icon && <div className='w-and-h-25px h-pr-fl-ma b-s-b-b'>{icon}</div>}
          {text  &&<div className='w-100 h-a b-s-b-b h-pr-fl-ma'>{text}{children}</div>}
          {iconRight && <div className='w-and-h-25px h-pr-fl-ma m-a p-l-3px b-s-b-b m-l-20px'><div className='w-a h-a centered'>{iconRight}</div></div>}
     
        </button>
        </a>
      ) : (
        <button
          className={`btn button ${type} ${className} h-pr-fl-ma ${size} flexbox f-w-n-w b-s-b-b`}
          onClick={onClick}
        >
          {iconLeft && <div className='w-and-h-25px h-pr-fl-ma b-s-b-b m-r-5px'><div className='w-a h-a centered'>{iconLeft}</div></div>}
          {icon && <div className='w-and-h-25px h-pr-fl-ma b-s-b-b'>{icon}</div>}
          {text  &&<div className='w-100 h-a b-s-b-b h-pr-fl-ma'>{text}{children}</div>}
          {iconRight && <div className='w-and-h-25px h-pr-fl-ma m-a p-l-3px b-s-b-b m-l-20px'><div className='w-a h-a centered'>{iconRight}</div></div>}
     
        </button>
      )}
    </>
  );
}

Btn.propTypes = {
  type: PropTypes.string,
  iconLeft: PropTypes.node,
  icon: PropTypes.node,
  altIconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  altIconRight: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  enlace: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func 
};

export default Btn;
